import React, { useEffect, useState } from "react";
import { Grid, LinearProgress, useTheme, useMediaQuery, Button, Box } from "@material-ui/core";
import ProductCard from 'src/components/ProductCard';
import { makeStyles } from "@material-ui/styles";
import ManagerApi from 'src/services/managerApi';
import { getBreakpointValue } from "./functions";
import { Skeleton } from "@material-ui/lab";
import { ProductSlider } from "./ProductSlider";
import { trackProductListView } from "src/utils/analytics";

const useStyles = makeStyles({
    skeleton: {
        opacity: 0,
        animation: '$show 1s',
    },
    "@keyframes show": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    },
    showMoreButton: {
        textTransform: 'none',
        fontSize: '12px',
        fontWeight: '500',
        color: '#555555',
        fontFamily: 'Manrope',
        border: '1px solid #777777',
        borderRadius: '100vw',
        padding: '2px 16px',
        marginTop: '4px',
    },
});

export const GridProducts = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';
    const [showAllProducts, setShowAllProducts] = useState(false);
    const [productList, setProductList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const productApi = new ManagerApi('/search/query');

    const { products, sliderInResponsive } = props;

    async function fetchProducts() {
        try {
            const productsIds = Array.isArray(products) && typeof products[0] === 'object' ? products.map(product => product.id) : products;

            const response = await productApi.getList({
                productIds: productsIds.join(','),
                status: 1
            }).catch(error => {
                throw error;
            });

            if (response.data.success) {
                const products = response.data.content.products;

                const sortedProducts = [];

                productsIds.map(productId => {
                    const product = products.find(product => product.id === productId);

                    if (product) {
                        sortedProducts.push(product);
                    }
                })

                setProductList(sortedProducts);
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    function renderGridItems() {
        function getGridItems(products) {
            return products.map(item => (
                <Grid key={item.id} item xs={getBreakpointValue(props, breakpoint, 'gridItemSize')}>
                    <ProductCard product={item} mobileVariant="horizontal" />
                </Grid>
            ));
        }

        let itemsToShow = productList;

        if (breakpoint === 'xs' && !showAllProducts) {
            itemsToShow = productList.slice(0, 3);
        }

        const gridItems = getGridItems(itemsToShow);

        if (breakpoint === 'xs' && productList.length > 3 && !showAllProducts) {
            gridItems.push(
                <Grid item xs={12} key="view-more-button">
                    <Box display="flex" justifyContent="center">
                        <Button className={classes.showMoreButton} onClick={() => setShowAllProducts(true)}>
                            Ver mais
                        </Button>
                    </Box>
                </Grid>
            );
        }

        return gridItems;
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        if (productList && productList.length > 0) {
            trackProductListView(productList, 'content_grid_products', 'Content Grid Products');
        }
    }, [productList]);

    return (
        <>
            {isLoading ? (
                <Grid
                    container
                    spacing={1}
                    className={classes.skeleton}
                >
                    {Array.from(new Array(8)).map((item, index) => (
                        <Grid key={index} item md={getBreakpointValue(props, breakpoint, 'gridItemSize')} xs={getBreakpointValue(props, breakpoint, 'gridItemSize')}>
                            <Skeleton variant="rectangular" width="100%" height={300} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <>
                    {productList.length > 0 ? (
                        <>
                            {sliderInResponsive && breakpoint === 'xs' ? (
                                <ProductSlider
                                    products={productList}
                                    slidesPerView={2}
                                />
                            ) : (
                                <Grid
                                    container
                                    spacing={1}
                                >
                                    {renderGridItems()}
                                </Grid>
                            )}
                        </>
                    ) : <></>}
                </>
            )}
        </>
    )
}
