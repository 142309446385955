import React, { useState, useEffect } from "react";
import { LinearProgress, useTheme, useMediaQuery } from "@material-ui/core";
import ManagerApi from 'src/services/managerApi';
import { getBreakpointValue } from "./functions";
import { ProductSlider } from "./ProductSlider";

export const SliderProduct = (props) => {
    const [productList, setProductList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const productApi = new ManagerApi('/search/query');

    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.only('xs')) ? 'xs' : 'md';

    const slidesPerView = Number(getBreakpointValue(props, breakpoint, 'slidesPerView'));

    const {
        products
    } = props;

    async function fetchProducts() {
        try {
            const productsIds = Array.isArray(products) && typeof products[0] === 'object' ? products.map(product => product.id) : products;

            const response = await productApi.getList({
                productIds: productsIds.join(','),
                status: 1
            }).catch(error => {
                throw error;
            });

            if (response.data.success) {
                const products = response.data.content.products;

                const sortedProducts = [];

                productsIds.map(productId => {
                    const product = products.find(product => product.id === productId);

                    if (product) {
                        sortedProducts.push(product);
                    }
                })

                setProductList(sortedProducts);
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <>
            {isLoading ? (
                <LinearProgress />
            ) : productList.length > 0 ? (
                <ProductSlider
                    products={productList}
                    slidesPerView={slidesPerView}
                />
            ) : <></>}
        </>
    )
}